/* eslint-disable no-console */
// import { takeEvery, call, put, fork, take } from "redux-saga/effects";
import { takeEvery, call, put, fork } from "redux-saga/effects";
import io from "socket.io-client";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child } from "firebase/database";
import { store, history } from "store/store";
import historyHelper from "historyHelper";

import { editAddressStore, initAddressesStore } from "store/actions/knxAddress";
import { knxState, internetState } from "store/actions/systemState";
import { actionSettingsSaveData } from "store/actions/settingsActions";
import {
  initConfigurationStore,
  initConfigurationServer,
} from "store/actions/configurationActions";
import { updateNotifications, updateBadgeNotifications } from "store/actions/notificationsActions";

import {
  actionSocketConnectionType,
  actionOnnaSocketStatus,
  actionServerSocketStatus,
  actionSocketInfo,
  // initSocket,
} from "store/actions/socketActions";
import { saveSchedulesFromServer, updateSchedulesFromServer } from "store/actions/scheduleActions";
// END

import constants from "store/constants";
import { updateAlarmStore } from "store/actions/alarmActions";
import { updateIntercomsStore } from "store/actions/intercomsActions";

import { saveMacrosFromServer, updateMacrosFromServer } from "store/actions/macroActions";
import { showModal } from "store/actions/modalActions";
import { setTrendData, setTrends, setTrendsHistory } from "store/actions/trendsActions";
import { setCloudApisApartment } from "store/actions/cloudApisActions";

import { updateOnnaDevicesState } from "store/actions/onnaDevicesActions";

import { updateMainUser } from "store/actions/mainUserActions";
import { newAlertMessage } from "store/actions/globalAlertActions";
import { getAuth, signOut } from "firebase/auth";

import { updateThermostatIp } from "store/actions/thermostatIPActions";
import socketConfig from "./socketConfig";

const socketPath = socketConfig();

let socketIoClient;
let socketIoMainUser;

let appStarted = false;

const firebaseApp = initializeApp({
  apiKey: "AIzaSyC5RvkP0yh89s-H4ZuBnr-_hJH6tnfst5I",
  authDomain: "onna-btr.firebaseapp.com",
  projectId: "onna-btr",
  storageBucket: "onna-btr.appspot.com",
  messagingSenderId: "853544952403",
  appId: "1:853544952403:web:85be895a55cb0c422fdca6",
  measurementId: "G-KMLWE81PQN",
  databaseURL: "onna-btr-default-rtdb.europe-west1.firebasedatabase.app",
});

const database = getDatabase(firebaseApp);

const {
  // USED
  GET_ONNA_DEVICES_STATE,
  ADD_ONNA_DEVICE_TO_PROJECT,
  REMOVE_ONNA_DEVICES_FROM_PROJECT,
  CHECK_CAPTCHA,
  CREATE_CLIENT_FIREBASE,
  REMOVE_CLIENT_FIREBASE,
  ACCESS_CONTROL_CHECK_CREDENTIALS,
  ACCESS_CONTROL_GET_LOCKS,
  ACCESS_CONTROL_GET_LOCK_PASSWORDS,
  ACCESS_CONTROL_REMOVE_LOCK_PASSWORD,
  ACCESS_CONTROL_GET_LOCK_DETAIL,
  ACCESS_CONTROL_LOCK_UNLOCK,
  ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT,
  ACCESS_CONTROL_LOCK_UNLOCK_CLIENT,
  ACCESS_CONTROL_CREATE_PASSWORD,
  GET_OCCUPANCY_DATA,
  GET_OCCUPANCY_DATA_XLSX,
  // USED
  SEND_VALUE_SERVER,
  GET_IP_ADDRESS,
  INIT_SOCKET,
  INIT_CLIENT,
  INIT_MAIN_USER_SOCKET,
  END_SOCKET,
  INIT_CONFIGURATION_SERVER,
  KNX_STATE_FROM_SERVER,
  INTERNET_STATE_FROM_SERVER,
  SETTINGS_SEND_DATA,
  // SEND_DATE_TIME,
  GET_DATE_TIME,
  UPDATE_APP,
  REBOOT_ONNA,
  SEND_CHANGE_OPEN_STATE,
  CREATE_MAIN_USER,
  UPLOAD_MAIN_USER_IMAGE,
  SEND_REMOVE_USER_LIST,
  SEND_PROTECT_SETTINGS_PAGE,
  ASK_NAVIGATE_PERMISSION,
  SEND_NEW_SCHEDULE_SERVER,
  SEND_EDIT_SCHEDULE_SERVER,
  SEND_REMOVE_SCHEDULE_SERVER,
  SEND_ENABLE_SCHEDULES_SERVER,
  SEND_READ_NOTIFICATION_SERVER,
  SEND_REMOVE_NOTIFICATION_SERVER,
  SEND_ARM_ALARM_SERVER,
  SEND_DISARM_ALARM_SERVER,
  SEND_CHANGE_PASSWORD_ALARM_SERVER,
  SEND_GROUP_ACTION_SERVER,
  SEND_EDIT_ROOM_NAME_SERVER,
  SEND_EDIT_ROOM_COLOR_SERVER,
  SEND_EDIT_DEVICE_NAME_SERVER,
  SEND_BUZZER_CLICK_SERVER,
  SEND_NEW_MACRO_SERVER,
  SEND_EDIT_MACRO_SERVER,
  SEND_REMOVE_MACRO_SERVER,
  SEND_EXECUTE_MACRO_SERVER,
  SEND_NEW_CALL_STATE_SERVER,
  SEND_NEW_VOLUMES_SERVER,
  SEND_TEST_AUDIO_SERVER,
  SEND_NEW_FAVOURITES_SERVER,
  GET_TREND_DATA,
  GET_TREND_DATA_XLSX,
  SET_TRENDS_HISTORY,
  STORE_TREND_HISTORY,
  REMOVE_TREND_DATA,
  CHANGE_THERMOSTATIP_VALUE,
} = constants;

// GLOBAL SOCKET

function ioGetValidCredentials(data) {
  return new Promise((resolve) => {
    const { deviceName, deviceId, apartmentId, mainUserId, userId } = data;
    socketIoMainUser.emit(
      "GET_VALID_CREDENTIALS",
      { deviceName, deviceId, userId, apartmentId, mainUserId },
      (result) => {
        const { error, message, onnaId } = result;
        if (!error) {
          resolve(onnaId);
        }
        if (error && message === "userHasNoGrant") {
          store.dispatch(
            newAlertMessage({
              type: "error",
              title: "user",
              content: "userHasNoGrant",
            })
          );
          const auth = getAuth();
          signOut(auth);
        }
        if (error && message === "userGrantTimeout") {
          store.dispatch(
            newAlertMessage({
              type: "error",
              title: "user",
              content: "userGrantTimeout",
            })
          );
          const auth = getAuth();
          signOut(auth);
        }
      }
    );
  });
}

const ioSendCheckCaptcha = (token) =>
  new Promise((resolve) => {
    const newCaptchaIo = io(`${socketPath}/captcha`, {
      forceNew: false,
    });
    newCaptchaIo.on("connect", () => {
      newCaptchaIo.emit("CHECK_CAPTCHA", token, (result) => {
        if (result) {
          newCaptchaIo.disconnect();
          newCaptchaIo.close();
          resolve(result);
        } else {
          newCaptchaIo.disconnect();
          newCaptchaIo.close();
          resolve();
        }
      });
    });
  });

function* sendCheckCaptcha({ data }) {
  const { token, resolve } = data;
  try {
    const result = yield call(ioSendCheckCaptcha, token);
    resolve(result);
  } catch (error) {
    console.log(error);
  }
}

const ioSendCreateMainUser = (userData) =>
  new Promise((resolve) => {
    const { uid, firstName, lastName, email, company, password1 } = userData;
    if (email && password1 && uid && firstName && lastName && company) {
      const newCaptchaIo = io(`${socketPath}/captcha`, {
        forceNew: false,
      });
      newCaptchaIo.on("connect", () => {
        newCaptchaIo.emit("CREATE_MAIN_USER", userData, (result) => {
          if (result) {
            newCaptchaIo.disconnect();
            newCaptchaIo.close();
            resolve(result);
          } else {
            newCaptchaIo.disconnect();
            newCaptchaIo.close();
            resolve(false);
          }
        });
      });
    } else {
      resolve(false);
    }
  });

function* sendCreateMainUser({ data }) {
  const { cb } = data;
  if (cb) {
    try {
      const response = yield call(ioSendCreateMainUser, data);
      cb(response);
    } catch (error) {
      cb(false);
    }
  }
}

const ioUploadMainUserImage = ({ image, uid }) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("UPLOAD_MAIN_USER_IMAGE", { image, uid }, (result) => {
      if (result) {
        resolve(result);
      } else {
        reject();
      }
    });
  });

function* uploadMainUserImage({ data }) {
  try {
    const { image, cb } = data;
    if (image && cb) {
      const uid = store.getState()?.mainUserReducer?.uid;
      const result = yield call(ioUploadMainUserImage, { image, uid });
      cb(result);
    }
  } catch (error) {
    console.log(error);
  }
}

const ioCreateClientFirebase = (clientData) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("CREATE_CLIENT_FIREBASE", clientData, (result, additionalData) => {
      if (result) {
        resolve({ result, additionalData });
      } else {
        reject();
      }
    });
  });

function* sendCreateClientFirebase({ data }) {
  try {
    const { clientData, resolve } = data;
    const { result, additionalData } = yield call(ioCreateClientFirebase, clientData);
    resolve({ result, additionalData });
  } catch (error) {
    console.log(error);
  }
}

const ioRemoveClientFirebase = (clientData) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("REMOVE_CLIENT_FIREBASE", clientData, (result) => {
      if (result) {
        resolve(result);
      } else {
        reject();
      }
    });
  });

function* sendRemoveClientFirebase({ data }) {
  try {
    const { clientData, resolve } = data;
    const result = yield call(ioRemoveClientFirebase, clientData);
    resolve(result);
  } catch (error) {
    console.log(error);
  }
}

const ioSendGetOnnaDevicesState = ({ data }) =>
  new Promise((resolve) => {
    socketIoMainUser.emit("GET_ONNA_DEVICES_STATE", data, (onnaDevicesStates) => {
      resolve(onnaDevicesStates);
    });
  });

function* sendGetOnnaDevicesState(data) {
  try {
    const onnaDevicesStates = yield call(ioSendGetOnnaDevicesState, data);
    yield put(updateOnnaDevicesState(onnaDevicesStates));
  } catch (error) {
    console.log(error);
  }
}

const ioSendAddOnnaDeviceToProject = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ADD_ONNA_DEVICE_TO_PROJECT", data, (response) => {
      if (response === true) {
        resolve();
      } else {
        reject(response);
      }
    });
  });

function* sendAddOnnaDeviceToProject({ data }) {
  const { resolve, reject, userId, onnaId, projectId } = data;
  try {
    yield call(ioSendAddOnnaDeviceToProject, { userId, onnaId, projectId });
    resolve();
  } catch (error) {
    reject(error);
  }
}

const ioSendRemoveOnnaDevicesFromProject = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("REMOVE_ONNA_DEVICES_FROM_PROJECT", data, (response) => {
      if (response) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendRemoveOnnaDevicesFromProject({ data }) {
  const { resolve, reject, userId, onnaIds } = data;
  try {
    yield call(ioSendRemoveOnnaDevicesFromProject, { userId, onnaIds });
    resolve();
  } catch (error) {
    reject();
  }
}

const ioSendAccessControlCheckCredentials = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_CHECK_CREDENTIALS", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlCheckCredentials({ data }) {
  const { userData, cb } = data;
  try {
    const response = yield call(ioSendAccessControlCheckCredentials, userData);
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlGetLocks = (projectId) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_GET_LOCKS", projectId, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlGetLocks({ data }) {
  const { cb, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlGetLocks, projectId);
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlGetLockPassowrds = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_GET_LOCK_PASSWORDS", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlGetLockPasswords({ data }) {
  const { cb, lockId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlGetLockPassowrds, { lockId, projectId });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlCreatePassword = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_CREATE_PASSWORD", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlCreatePassword({ data }) {
  const { name, password, lockId, startDate, endDate, projectId, cb } = data;
  try {
    const response = yield call(ioSendAccessControlCreatePassword, {
      name,
      password,
      lockId,
      startDate,
      endDate,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlRemoveLockPassword = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_REMOVE_LOCK_PASSWORD", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlRemoveLockPassword({ data }) {
  const { cb, lockId, keyboardPwdId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlRemoveLockPassword, {
      lockId,
      keyboardPwdId,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlGetLockDetail = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_GET_LOCK_DETAIL", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlGetLockDetail({ data }) {
  const { cb, lockId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlGetLockDetail, {
      lockId,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlLockUnlock = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("ACCESS_CONTROL_LOCK_UNLOCK", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlLockUnlock({ data }) {
  const { cb, lockId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlLockUnlock, {
      lockId,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

// CLIENT SOCKET

const ioSendAccessControlGetLockDetailClient = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlGetLockDetailClient({ data }) {
  const { cb, lockId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlGetLockDetailClient, {
      lockId,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendAccessControlLockUnlockClient = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("ACCESS_CONTROL_LOCK_UNLOCK_CLIENT", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendAccessControlLockUnlockClient({ data }) {
  const { cb, lockId, projectId } = data;
  try {
    const response = yield call(ioSendAccessControlLockUnlockClient, {
      lockId,
      projectId,
    });
    cb(response);
  } catch (error) {
    cb(false);
  }
}

const ioSendGetOccupancyData = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("GET_OCCUPANCY_DATA", data, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendGetOccupancyData({ data }) {
  const { cb, projectId, start, stop } = data;
  try {
    const response = yield call(ioSendGetOccupancyData, {
      projectId,
      start,
      stop,
    });
    cb(response);
  } catch (error) {
    cb([]);
  }
}

const ioSendGetOccupancyDataXlsxServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoMainUser.emit("GET_OCCUPANCY_DATA_XLSX", data, (requestedInfo) => {
      if (requestedInfo) {
        resolve(requestedInfo);
      } else {
        reject();
      }
    });
  });

function* sendGetOccupancyDataXlsxGenerator(request) {
  try {
    const { data } = request;
    const { startDate, endDate, projectId } = data;
    const offset = new Date().getTimezoneOffset();
    let language = "en-gb";
    if (navigator.languages !== undefined) {
      // eslint-disable-next-line prefer-destructuring
      language = navigator.languages[0];
    } else {
      language = navigator.language;
    }
    const response = yield call(ioSendGetOccupancyDataXlsxServer, {
      projectId,
      start: new Date(startDate).toISOString(),
      stop: new Date(endDate).toISOString(),
      offset,
      language,
    });
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `${startDate}_${endDate}_${projectId}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
}

const ioSendValueServer = (addressToSend) =>
  new Promise((resolve) => {
    socketIoClient.emit("SET_ADDRESS_VALUE_FROM_CLIENT", addressToSend, (success) => {
      resolve(success);
    });
  }).then((success) => success);

function* sendValueServerGenerator({ updates }) {
  try {
    const addressToSend = updates;
    const success = yield call(ioSendValueServer, addressToSend);
    if (success) {
      yield put(editAddressStore(addressToSend)); // COMMENT for test ONE
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * ONNA Addresses
 */

const buildAdressesArray = (addressesFromServer, valuesFromServer) => {
  const addresses = [];
  if (addressesFromServer && valuesFromServer) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in addressesFromServer) {
      addresses.push({
        ...addressesFromServer[i],
        ...valuesFromServer[i],
      });
    }
  }
  return addresses;
};

/**
 * ONNA Configuration
 */

const ioInitConfigurationServer = (userData) =>
  new Promise((resolve, reject) => {
    const { userName } = userData;
    socketIoClient.emit(
      `${userName === "cloud" ? "INIT_CONFIGURATION" : "INIT_CONFIGURATION_FROM_CLIENT"}`,
      (data) => {
        if (data) {
          const {
            addressesFromServer,
            valuesFromServer,
            configurationFromServer,
            alarm,
            intercoms,
            schedules,
            notifications,
            macros,
            usersList,
            premiumServices,
            trends,
            favourites,
            cloudApis,
            thermostatIpDevices,
          } = data;
          resolve({
            addressesFromServer,
            valuesFromServer,
            configurationFromServer,
            alarm,
            intercoms,
            schedules,
            notifications,
            macros,
            usersList,
            premiumServices,
            trends,
            favourites,
            cloudApis,
            thermostatIpDevices,
          });
        } else {
          reject();
        }
      }
    );
  }).then((data) => data);

function* initConfigurationServerGenerator(userData) {
  try {
    // Configuration
    const {
      addressesFromServer,
      valuesFromServer,
      configurationFromServer,
      macros,
      trends,
      schedules,
      notifications,
      cloudApis,
      thermostatIpDevices,
    } = yield call(ioInitConfigurationServer, userData);

    if (thermostatIpDevices) {
      store.dispatch(updateThermostatIp(thermostatIpDevices));
    }

    const { userName } = userData;

    const addressesArray = buildAdressesArray(addressesFromServer, valuesFromServer);

    if (addressesArray.length === 0) {
      yield put(actionOnnaSocketStatus(false));
    } else {
      yield put(initAddressesStore(addressesArray));
      yield put(actionOnnaSocketStatus(true));
    }

    if (configurationFromServer) {
      const { configuration, settings } = configurationFromServer;

      yield put(
        initConfigurationStore(configuration.filter((apartment) => apartment.nodeId !== "home"))
      );
      yield put(actionSettingsSaveData(settings));
    }

    if (trends) yield put(setTrends(trends));
    if (macros) {
      yield put(saveMacrosFromServer(macros));
    }
    if (schedules) yield put(saveSchedulesFromServer(schedules));
    if (userName === "web" && notifications) {
      store.dispatch(updateNotifications(notifications));
      let badgeNumber = 0;
      Object.values(notifications || {}).forEach((notification) => {
        if (!notification.read) badgeNumber += 1;
      });
      store.dispatch(updateBadgeNotifications(badgeNumber));
    }
    if (cloudApis) yield put(setCloudApisApartment(cloudApis));
  } catch (e) {
    console.log("Init socket error");
  }
}

const ioSendKnxStateServer = () =>
  new Promise((resolve) => {
    socketIoClient.emit("KNX_STATE_FROM_CLIENT", (state) => {
      resolve(state);
    });
  });

function* sendKnxStateServerGenerator() {
  const state = yield call(ioSendKnxStateServer);
  yield put(knxState(state));
}

function* initCalls(clientData) {
  yield call(initConfigurationServerGenerator, clientData);
  yield call(sendKnxStateServerGenerator);
}

function ioSendSettingsData(settingsData) {
  const { data } = settingsData;
  return new Promise((resolve, reject) => {
    socketIoClient.emit("SEND_SETTINGS_DATA_FROM_CLIENT", data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendSettingsData(settingsData) {
  try {
    yield call(ioSendSettingsData, settingsData);
    yield put(actionSettingsSaveData(settingsData?.data));
  } catch (error) {
    console.log(error);
  }
}

function ioGetDateTime() {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("GET_DATE_TIME_FROM_CLIENT", (timeData) => {
      if (timeData) {
        resolve(timeData);
      } else {
        reject();
      }
    });
  });
}

function* getDateTime({ cb }) {
  try {
    const timeData = yield call(ioGetDateTime);
    cb(timeData);
  } catch (error) {
    console.log(error);
  }
}

function ioUpdateData(updateData) {
  const { data } = updateData;
  return new Promise((resolve) => {
    socketIoClient.emit("SEND_UPDATE_APP_FROM_CLIENT", data, (success) => {
      if (success === "ok") {
        resolve();
      } else {
        resolve();
      }
    });
  });
}

function* updateApp(updateData) {
  try {
    yield call(ioUpdateData, updateData);
  } catch (error) {
    console.log(error);
  }
}

function ioSendRebootOnna() {
  return new Promise((resolve) => {
    socketIoClient.emit("SEND_REBOOT_ONNA_FROM_CLIENT", (success) => {
      if (success) {
        resolve();
      } else {
        resolve();
      }
    });
  });
}

function* sendRebootOnna() {
  try {
    yield call(ioSendRebootOnna);
  } catch (error) {
    console.log(error);
  }
}

// End

/** **********
 * SCHEDULE *
 ********** */

const ioSendNewSchedulesServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("NEW_SCHEDULE_FROM_CLIENT", data.data.nodeId, data.data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
// NEW Scheduler
function* sendNewScheduleServerGenerator(data) {
  try {
    yield call(ioSendNewSchedulesServer, data);
  } catch (error) {
    console.log(error);
  }
}

// EDIT Scheduler
function ioSendEditScheduleServer({ data }) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("EDIT_SCHEDULE_FROM_CLIENT", data.nodeId, data.id, data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendEditScheduleServerGenerator(data) {
  try {
    yield call(ioSendEditScheduleServer, data);
  } catch (error) {
    console.log(error);
  }
}

// REMOVE Scheduler
function ioSendRemoveScheduleServer({ data }) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("REMOVE_SCHEDULE_FROM_CLIENT", data.nodeId, data.id, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendRemoveScheduleServerGenerator(data) {
  try {
    yield call(ioSendRemoveScheduleServer, data);
  } catch (error) {
    console.log(error);
  }
}

function ioSendEnableSchedulesServer({ data }) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("ENABLE_SCHEDULES_FROM_CLIENT", data.nodeId, data.enable, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendEnableSchedulesServerGenerator(data) {
  try {
    yield call(ioSendEnableSchedulesServer, data);
  } catch (error) {
    console.log(error);
  }
}

// END

/** **********
 * ALARM *
 ********** */

// ARM

function ioSendArmAlarmServer(data) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("ARM_ALARM_FROM_CLIENT", data.partial, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendArmAlarmServerGenerator(data) {
  try {
    yield call(ioSendArmAlarmServer, data);
  } catch (error) {
    console.log(error);
  }
}

// DISARM

function ioSendDisarmAlarmServer(data) {
  return new Promise((resolve) => {
    socketIoClient.emit("DISARM_ALARM_FROM_CLIENT", data.pin, (success) => {
      if (!success)
        store.dispatch(
          showModal({
            title: "alertTitleAlarm",
            body: "alertBodyPINWrong",
            showModal: true,
            buttons: 1,
            button1Text: "alertBtnOk",
          })
        );
      resolve();
    });
  });
}

function* sendDisarmAlarmServerGenerator(data) {
  yield call(ioSendDisarmAlarmServer, data);
}

function ioSendChangePasswordAlarmServer(data) {
  return new Promise((resolve) => {
    socketIoClient.emit("CHANGE_PASSWORD_ALARM_CLIENT", data, (success) => {
      if (success) {
        store.dispatch(
          showModal({
            title: "alertTitleInformation",
            body: "pinCodeChanged",
            showModal: true,
            buttons: 1,
            button1Text: "alertBtnOk",
          })
        );
      } else {
        store.dispatch(
          showModal({
            title: "alertTitleInformation",
            body: "pinCodeWrong",
            showModal: true,
            buttons: 1,
            button1Text: "alertBtnOk",
          })
        );
      }
      resolve();
    });
  });
}

function* sendChangePasswordAlarmServerGenerator(action) {
  const { data } = action;
  yield call(ioSendChangePasswordAlarmServer, data);
}

// END

/** **********
 * MACROS *
 ********** */

// NEW Macro

const ioSendNewMacroServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit(
      "NEW_MACRO_FROM_CLIENT",
      data.data.roomNodeId,
      data.data.macro,
      (success) => {
        if (success) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

function* sendNewMacroServerGenerator(data) {
  try {
    yield call(ioSendNewMacroServer, data);
  } catch (error) {
    console.log(error);
  }
}

// EDIT Macro

const ioSendEditMacroServer = ({ data }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit(
      "EDIT_MACRO_FROM_CLIENT",
      data.roomNodeId,
      data.macroId,
      data.macro,
      (success) => {
        if (success) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

function* sendEditMacroServerGenerator(data) {
  try {
    yield call(ioSendEditMacroServer, data);
  } catch (error) {
    console.log(error);
  }
}

// REMOVE Macro
const ioSendRemoveMacroServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit(
      "REMOVE_MACRO_FROM_CLIENT",
      data.data.roomNodeId,
      data.data.macroId,
      (success) => {
        if (success) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

function* sendRemoveMacroServerGenerator(data) {
  try {
    yield call(ioSendRemoveMacroServer, data);
  } catch (error) {
    console.log(error);
  }
}

// EXECUTE Macro

const ioSendExecuteMacroServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit(
      "EXECUTE_MACRO_FROM_CLIENT",
      data.data.roomNodeId,
      data.data.macroId,
      (success) => {
        if (success) {
          resolve();
        } else {
          reject();
        }
      }
    );
  });

function* sendExecuteMacroServerGenerator(data) {
  try {
    yield call(ioSendExecuteMacroServer, data);
  } catch (error) {
    console.log(error);
  }
}

// END

/** *********
 * SOCKET IO
 ********** */

// FIREBASE

const getSocket = (receivedData) =>
  new Promise((resolve, reject) => {
    if (socketIoClient) {
      socketIoClient.disconnect();
    }
    const { userId, onnaId, apartmentId, userName, clientUserId = "", token } = receivedData;
    if (userId && onnaId) {
      const newSocketIo = io(`${socketPath}/${onnaId}`, {
        auth: {
          userId,
          apartmentId,
          userName,
          expoToken: null,
          onnaId,
          clientUserId,
          token,
        },
        forceNew: false,
      });
      newSocketIo.on("connect", () => {
        store.dispatch(actionSocketConnectionType("local"));
        store.dispatch(actionOnnaSocketStatus(true));
        resolve(newSocketIo);
      });

      newSocketIo.on("connect_error", (error) => {
        console.log(error);
      });
    } else {
      reject();
    }
  });

function* initSocketServerGenerator({ data: receivedData }) {
  try {
    const { userName, clientUserId } = receivedData;
    store.dispatch(actionOnnaSocketStatus(false));
    socketIoClient = yield call(getSocket, receivedData);
    yield call(initCalls, receivedData);
    const { id } = socketIoClient;
    const { uri } = io;

    socketIoClient.on("error", (error) => {
      console.log(error);
    });

    socketIoClient.on("NEW_CONFIGURATION_FROM_SERVER", () => {
      store.dispatch(initConfigurationServer());
    });

    socketIoClient.on("ONNA_DISCONNECTED", () => {
      store.dispatch(actionServerSocketStatus(false));
      socketIoClient.disconnect();
    });

    // yield put(actionLastIpConnection({ uri }));

    yield put(actionSocketInfo({ nameSpace: uri, socketID: id }));

    // Listen SOCKET
    socketIoClient.on("SET_ADDRESS_VALUE_FROM_SERVER", (data) => {
      store.dispatch(editAddressStore(data));
    });

    // Update schedules
    socketIoClient.on("UPDATE_SCHEDULES_FROM_SERVER", (schedules, apartmentId) => {
      if (userName === "cloud") {
        store.dispatch(updateSchedulesFromServer({ schedules, apartmentId }));
      } else {
        store.dispatch(updateSchedulesFromServer({ schedules }));
      }
    });

    // Update notifications
    socketIoClient.on("UPDATE_NOTIFICATIONS_FROM_SERVER", (notifications) => {
      if (userName === "web" && notifications) {
        store.dispatch(updateNotifications(notifications));
        let badgeNumber = 0;
        Object.values(notifications || {}).forEach((notification) => {
          if (!notification.read) badgeNumber += 1;
        });
        store.dispatch(updateBadgeNotifications(badgeNumber));
      }
    });

    // Update alarm
    socketIoClient.on("UPDATE_ALARM_FROM_SERVER", (data) => {
      store.dispatch(updateAlarmStore(data));
    });

    // Update Macros
    socketIoClient.on("UPDATE_MACROS_FROM_SERVER", (macros, apartmentId) => {
      if (userName === "cloud") {
        store.dispatch(updateMacrosFromServer({ macros, apartmentId }));
      } else {
        store.dispatch(updateMacrosFromServer({ macros }));
      }
    });

    // Intercoms

    socketIoClient.on("NEW_CALL_STATE_FROM_SERVER", (data) => {
      store.dispatch(updateIntercomsStore({ callState: data }));
    });

    socketIoClient.on("NEW_VOLUMES_FROM_SERVER", (data) => {
      store.dispatch(updateIntercomsStore(data));
    });

    // KNX Port Status
    socketIoClient.on(KNX_STATE_FROM_SERVER, (data) => {
      store.dispatch(knxState(data));
    });

    // ThermostatsIP
    socketIoClient.on("UPDATE_THERMOSTATIP_STATE_IN_REDUCER", (data) => {
      store.dispatch(updateThermostatIp(data));
    });

    // Internet State
    socketIoClient.on(INTERNET_STATE_FROM_SERVER, (data) => {
      store.dispatch(internetState(data));
    });

    socketIoClient.on("INIT_ADDRESES_FROM_SERVER", (addressesFromServer, valuesFromServer) => {
      if (addressesFromServer && valuesFromServer) {
        const addresses = [];

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in addressesFromServer) {
          addresses.push({
            ...addressesFromServer[i],
            ...valuesFromServer[i],
          });
        }
        store.dispatch(initAddressesStore(addresses));
      }
    });

    // Trends History
    socketIoClient.on(SET_TRENDS_HISTORY, (data) => {
      store.dispatch(setTrendsHistory(data));
    });

    socketIoClient.on("USER_CHECKOUT", (userId) => {
      if (userId === clientUserId) {
        store.dispatch(signOut());
      }
    });

    socketIoClient.on("disconnect", () => {
      store.dispatch(actionOnnaSocketStatus(false));
      store.dispatch(actionServerSocketStatus(false));
    });
  } catch (e) {
    console.log(e);
  }
}

function ioInitClientGenerator(data) {
  return new Promise((resolve, reject) => {
    const databaseRef = ref(database);
    get(child(databaseRef, `clientsData/${data}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const { apartmentId, mainUserId, projectId } = snapshot.val();
          const values = {
            apartmentId,
            mainUserId,
            projectId,
            userId: data,
            deviceId: "web",
          };
          resolve(values);
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
}

function* initClientGenerator({ data }) {
  try {
    const { uid, email, token } = data;
    const userData = yield call(ioInitClientGenerator, uid);
    if (userData) {
      const { apartmentId, mainUserId, projectId } = userData;
      store.dispatch(updateMainUser({ uid, email, projectId, isClient: true, isValidated: true }));

      const onnaId = yield call(ioGetValidCredentials, userData);
      if (onnaId !== "virtual") {
        yield call(initSocketServerGenerator, {
          data: {
            userId: mainUserId,
            onnaId,
            apartmentId,
            userName: "web",
            clientUserId: uid,
            token,
          },
        });
        store.dispatch(updateMainUser({ apartmentId }));
      }

      historyHelper.navigate(`/client/apartment`);
    }
  } catch (error) {
    console.log(error);
  }
}

function ioInitMainUserSocketGenerator(data) {
  return new Promise((resolve, reject) => {
    const { uid, token } = data;
    if (uid && token) {
      const newSocketIoMainUser = io(`${socketPath}/global`, {
        auth: {
          userId: uid,
          userName: `cloud`,
          token,
        },
        forceNew: false,
      });
      newSocketIoMainUser.on("connect", () => {
        resolve(newSocketIoMainUser);
      });
      newSocketIoMainUser.on("connect_error", (error) => {
        reject(error);
      });
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject("Missing token or uid");
    }
  });
}

function* initMainUserSocketGenerator({ data }) {
  const { uid, token, cb } = data;
  try {
    const newSocketIoMainUser = yield call(ioInitMainUserSocketGenerator, { uid, token });
    socketIoMainUser = newSocketIoMainUser;
    cb(true);
  } catch (error) {
    cb(false);
  }
}

function* stopSocket() {
  if (socketIoClient) {
    socketIoClient.disconnect();
    socketIoClient = undefined;
  }
  yield put(initAddressesStore([]));
  yield put(initConfigurationStore([]));
  yield put(actionSettingsSaveData({}));
  yield put(saveMacrosFromServer({}));
  yield put(saveSchedulesFromServer({}));
  yield put(
    setTrends({
      trendData: [],
      trends: [],
      trendsHistory: {},
    })
  );
  yield put(knxState(false));
  yield put(internetState(false));
}

const sendGetIpAddress = () =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("GET_IP_ADDRESS", (ipAddress) => {
      if (ipAddress) {
        resolve(ipAddress);
      } else {
        reject();
      }
    });
  });

function* sendGetIpAddressGenerator() {
  try {
    const ipAddress = yield call(sendGetIpAddress);
    yield put(actionSettingsSaveData({ ipAddress }));
  } catch (error) {
    console.log(error);
  }
}

// END SocketIO

/**
 *
 * @param {userName, onnaId, token, state } data
 */

// GLOBAL ACTIONS

const ioSendGroupActionServer = ({ payload }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("GROUP_ACTION_FROM_CLIENT", payload, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendGroupActionServerGenerator(data) {
  try {
    yield call(ioSendGroupActionServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendEditRoomNameServer = ({ payload }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("EDIT_ROOM_NAME_FROM_CLIENT", payload, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendEditRoomNameServerGenerator(data) {
  try {
    yield call(ioSendEditRoomNameServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendEditRoomColorServer = ({ payload }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("EDIT_ROOM_COLOR_FROM_CLIENT", payload, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendEditRoomColorServerGenerator(data) {
  try {
    yield call(ioSendEditRoomColorServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendEditDeviceNameServer = ({ payload }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("EDIT_DEVICE_NAME_FROM_CLIENT", payload, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendEditDeviceNameServerGenerator(data) {
  try {
    yield call(ioSendEditDeviceNameServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendBuzzerClick = () =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("ONNA_BUZZER_CLICK", (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendBuzzerClickGenerator() {
  try {
    yield call(ioSendBuzzerClick);
  } catch (error) {
    console.log(error);
  }
}

const ioChangeOpenState = (state) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("ONNA_CHANGE_OPEN_STATE", state, (success) => {
      if (success) {
        resolve(state);
      } else {
        reject();
      }
    });
  });

function* sendChangeOpenStateGenerator(state) {
  try {
    const { data } = state;
    const newState = yield call(ioChangeOpenState, data);
    yield put(actionSettingsSaveData({ openState: newState }));
  } catch (error) {
    console.log(error);
  }
}

const ioRemoveUserList = (user) =>
  new Promise((resolve) => {
    socketIoClient.emit("ONNA_REMOVE_USER_LIST", user);
    resolve();
  });

function* sendRemoveUserList(data) {
  const { user } = data;
  try {
    yield call(ioRemoveUserList, user);
  } catch (error) {
    console.log(error);
  }
}

const ioProtectSettingsPage = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("PROTECT_SETTINGS_PAGE_FROM_CLIENT", data, (success) => {
      if (!success) {
        store.dispatch(
          showModal({
            title: "alertTitleInformation",
            body: "alertBodyPINWrong",
            showModal: true,
            buttons: 1,
            button1Text: "alertBtnOk",
          })
        );
        reject();
      }
      resolve();
    });
  });

function* sendProtectSettingsPage({ protectSettingsPage, pin }) {
  try {
    yield call(ioProtectSettingsPage, { protectSettingsPage, pin });
    yield put(actionSettingsSaveData({ protectSettingsPage }));
  } catch (error) {
    console.log(error);
  }
}

const ioSendNavigatePermission = (pin) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("ASK_NAVIGATE_PERMISSION_FROM_CLIENT", pin, (success) => {
      if (!success) {
        store.dispatch(
          showModal({
            title: "alertTitleInformation",
            body: "alertBodyPINWrong",
            showModal: true,
            buttons: 1,
            button1Text: "alertBtnOk",
          })
        );
        reject();
      }
      resolve();
    });
  });

function* sendNavigatePermission({ pin, url }) {
  try {
    yield call(ioSendNavigatePermission, pin);
    history.push(url);
  } catch (error) {
    console.log(error);
  }
}

const ioSendNewCallStateServer = ({ data }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("NEW_CALL_STATE_FROM_CLIENT", data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendNewCallStateServerGenerator(data) {
  try {
    yield call(ioSendNewCallStateServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendNewVolumesServer = ({ data }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("NEW_VOLUME_VALUES_FROM_CLIENT", data, (success) => {
      if (success) {
        resolve(data);
      } else {
        reject();
      }
    });
  });

function* sendNewVolumesServerGenerator(data) {
  try {
    yield call(ioSendNewVolumesServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendTestAudioServer = () =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("TEST_AUDIO_FROM_CLIENT", (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendTestAudioServerGenerator() {
  try {
    yield call(ioSendTestAudioServer);
  } catch (error) {
    console.log(error);
  }
}

const ioSendNewFavouritesServer = ({ data }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("NEW_FAVOURITES_FROM_CLIENT", data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* sendNewFavouritesGenerator(data) {
  try {
    yield call(ioSendNewFavouritesServer, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendGetTrendDataServer = ({ data }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("GET_TREND_DATA", data, (requestedInfo) => {
      if (requestedInfo) {
        resolve(requestedInfo);
      } else {
        reject();
      }
    });
  });

function* sendGetTrendDataGenerator(data) {
  try {
    const trendData = yield call(ioSendGetTrendDataServer, data);
    yield put(setTrendData(trendData));
  } catch (error) {
    console.log(error);
  }
}

const ioSendGetTrendDataXlsxServer = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("GET_TREND_DATA_XLSX", data, (requestedInfo) => {
      if (requestedInfo) {
        resolve(requestedInfo);
      } else {
        reject();
      }
    });
  });

function* sendGetTrendDataXlsxGenerator(request) {
  try {
    const { data } = request;
    const { startDate, endDate, address } = data;
    const response = yield call(ioSendGetTrendDataXlsxServer, data);
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = `${startDate}_${endDate}_${address}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
}

const ioChangeThermostatValue = (data) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("UPDATE_THERMOSTATIP_PROPERTY_BY_USER", data, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });

function* changeThermostatValue({ data }) {
  try {
    yield call(ioChangeThermostatValue, data);
  } catch (error) {
    console.log(error);
  }
}

const ioSendStoreTrendHistory = ({ address, lastDay, lastMonth }) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("STORE_TREND_HISTORY", { address, lastDay, lastMonth }, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendStoreTrendHistory(request) {
  try {
    const { data } = request;
    const { cb } = data;
    const response = yield call(ioSendStoreTrendHistory, data);
    cb(response);
  } catch (error) {
    console.log(error);
  }
}

const ioSendRemoveTrendData = (device) =>
  new Promise((resolve, reject) => {
    socketIoClient.emit("REMOVE_TREND_DATA", device, (response) => {
      if (response) {
        resolve(response);
      } else {
        reject();
      }
    });
  });

function* sendRemoveTrendData(request) {
  try {
    const { data } = request;
    const { device, cb } = data;
    const response = yield call(ioSendRemoveTrendData, device);
    if (response) yield put(setTrendData([]));
    cb(response);
  } catch (error) {
    console.log(error);
  }
}

function ioSendSendReadNotificationServer(data) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("READ_NOTIFICATION_FROM_CLIENT", data.id, data.apartmentId, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendReadNotificationServerGenerator(data) {
  try {
    yield call(ioSendSendReadNotificationServer, data);
  } catch (error) {
    console.log(error);
  }
}

function ioSendRemoveNotificationServer(data) {
  return new Promise((resolve, reject) => {
    socketIoClient.emit("REMOVE_NOTIFICATION_FROM_CLIENT", data.id, data.apartmentId, (success) => {
      if (success) {
        resolve();
      } else {
        reject();
      }
    });
  });
}

function* sendRemoveNotificationServerGenerator(data) {
  try {
    yield call(ioSendRemoveNotificationServer, data);
  } catch (error) {
    console.log(error);
  }
}

// END

function* mainLoop() {
  // GLOBAL SOCKET

  yield takeEvery(GET_ONNA_DEVICES_STATE, sendGetOnnaDevicesState);
  yield takeEvery(ADD_ONNA_DEVICE_TO_PROJECT, sendAddOnnaDeviceToProject);
  yield takeEvery(REMOVE_ONNA_DEVICES_FROM_PROJECT, sendRemoveOnnaDevicesFromProject);
  yield takeEvery(CREATE_MAIN_USER, sendCreateMainUser);
  yield takeEvery(UPLOAD_MAIN_USER_IMAGE, uploadMainUserImage);
  yield takeEvery(CHECK_CAPTCHA, sendCheckCaptcha);
  yield takeEvery(CREATE_CLIENT_FIREBASE, sendCreateClientFirebase);
  yield takeEvery(REMOVE_CLIENT_FIREBASE, sendRemoveClientFirebase);
  yield takeEvery(ACCESS_CONTROL_CHECK_CREDENTIALS, sendAccessControlCheckCredentials);
  yield takeEvery(ACCESS_CONTROL_GET_LOCKS, sendAccessControlGetLocks);
  yield takeEvery(ACCESS_CONTROL_GET_LOCK_PASSWORDS, sendAccessControlGetLockPasswords);
  yield takeEvery(ACCESS_CONTROL_REMOVE_LOCK_PASSWORD, sendAccessControlRemoveLockPassword);
  yield takeEvery(ACCESS_CONTROL_GET_LOCK_DETAIL, sendAccessControlGetLockDetail);
  yield takeEvery(ACCESS_CONTROL_LOCK_UNLOCK, sendAccessControlLockUnlock);
  yield takeEvery(ACCESS_CONTROL_GET_LOCK_DETAIL_CLIENT, sendAccessControlGetLockDetailClient);
  yield takeEvery(ACCESS_CONTROL_LOCK_UNLOCK_CLIENT, sendAccessControlLockUnlockClient);
  yield takeEvery(ACCESS_CONTROL_CREATE_PASSWORD, sendAccessControlCreatePassword);

  yield takeEvery(GET_OCCUPANCY_DATA, sendGetOccupancyData);
  yield takeEvery(GET_OCCUPANCY_DATA_XLSX, sendGetOccupancyDataXlsxGenerator);

  // CLIENT SOCKET

  yield takeEvery(INIT_SOCKET, initSocketServerGenerator);
  yield takeEvery(INIT_CLIENT, initClientGenerator);
  yield takeEvery(INIT_MAIN_USER_SOCKET, initMainUserSocketGenerator);

  yield takeEvery(SEND_VALUE_SERVER, sendValueServerGenerator);
  yield takeEvery(END_SOCKET, stopSocket);
  yield takeEvery(GET_TREND_DATA, sendGetTrendDataGenerator);
  yield takeEvery(GET_TREND_DATA_XLSX, sendGetTrendDataXlsxGenerator);
  yield takeEvery(STORE_TREND_HISTORY, sendStoreTrendHistory);
  yield takeEvery(REMOVE_TREND_DATA, sendRemoveTrendData);

  yield takeEvery(UPDATE_APP, updateApp);

  // MACRO
  yield takeEvery(SEND_NEW_MACRO_SERVER, sendNewMacroServerGenerator);
  yield takeEvery(SEND_EDIT_MACRO_SERVER, sendEditMacroServerGenerator);
  yield takeEvery(SEND_REMOVE_MACRO_SERVER, sendRemoveMacroServerGenerator);
  yield takeEvery(SEND_EXECUTE_MACRO_SERVER, sendExecuteMacroServerGenerator);

  // SCHEDULE
  yield takeEvery(SEND_NEW_SCHEDULE_SERVER, sendNewScheduleServerGenerator);
  yield takeEvery(SEND_EDIT_SCHEDULE_SERVER, sendEditScheduleServerGenerator);
  yield takeEvery(SEND_REMOVE_SCHEDULE_SERVER, sendRemoveScheduleServerGenerator);
  yield takeEvery(SEND_ENABLE_SCHEDULES_SERVER, sendEnableSchedulesServerGenerator);

  yield takeEvery(SEND_READ_NOTIFICATION_SERVER, sendReadNotificationServerGenerator);

  yield takeEvery(SEND_REMOVE_NOTIFICATION_SERVER, sendRemoveNotificationServerGenerator);

  yield takeEvery(GET_DATE_TIME, getDateTime);

  yield takeEvery(SETTINGS_SEND_DATA, sendSettingsData);

  // THERMOSTATSIP
  yield takeEvery(CHANGE_THERMOSTATIP_VALUE, changeThermostatValue);

  while (false) {
    if (!appStarted) {
      appStarted = true;
    }
    // To be able to restart every rerender while testing

    yield takeEvery(INIT_CONFIGURATION_SERVER, initCalls);

    yield takeEvery(REBOOT_ONNA, sendRebootOnna);

    // END

    // ALARM
    yield takeEvery(SEND_ARM_ALARM_SERVER, sendArmAlarmServerGenerator);
    yield takeEvery(SEND_DISARM_ALARM_SERVER, sendDisarmAlarmServerGenerator);
    yield takeEvery(SEND_CHANGE_PASSWORD_ALARM_SERVER, sendChangePasswordAlarmServerGenerator);

    // END

    // GLOBAL ACTIONS
    yield takeEvery(GET_IP_ADDRESS, sendGetIpAddressGenerator);

    yield takeEvery(SEND_GROUP_ACTION_SERVER, sendGroupActionServerGenerator);

    yield takeEvery(SEND_EDIT_ROOM_NAME_SERVER, sendEditRoomNameServerGenerator);
    yield takeEvery(SEND_EDIT_ROOM_COLOR_SERVER, sendEditRoomColorServerGenerator);
    yield takeEvery(SEND_EDIT_DEVICE_NAME_SERVER, sendEditDeviceNameServerGenerator);
    yield takeEvery(SEND_BUZZER_CLICK_SERVER, sendBuzzerClickGenerator);

    yield takeEvery(SEND_CHANGE_OPEN_STATE, sendChangeOpenStateGenerator);
    yield takeEvery(SEND_REMOVE_USER_LIST, sendRemoveUserList);

    yield takeEvery(SEND_PROTECT_SETTINGS_PAGE, sendProtectSettingsPage);
    yield takeEvery(ASK_NAVIGATE_PERMISSION, sendNavigatePermission);

    // END

    // INTERCOMS
    yield takeEvery(SEND_NEW_CALL_STATE_SERVER, sendNewCallStateServerGenerator);
    yield takeEvery(SEND_NEW_VOLUMES_SERVER, sendNewVolumesServerGenerator);

    yield takeEvery(SEND_TEST_AUDIO_SERVER, sendTestAudioServerGenerator);

    yield takeEvery(SEND_NEW_FAVOURITES_SERVER, sendNewFavouritesGenerator);

    // TRENDS

    // END
  }
}

export default function* primaryFunction() {
  yield fork(mainLoop);
}
