/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { useState } from "react";

// @mui material components
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Room from "layouts/apartment/Room";
import Macro from "layouts/apartment/Devices/Macro";
import Schedule from "layouts/apartment/Devices/Schedule";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import ScheduleEdit from "layouts/apartment/Devices/schedule/ScheduleEdit";
import MacroEdit from "layouts/apartment/Devices/macro/MacroEdit";
import MDIconButton from "components/MDIconButton";

import { initSocket, endSocket } from "store/actions/socketActions";
import { sendExecuteMacroServer } from "store/actions/macroActions";
import MDAppBar from "components/MDAppBar";
import CloudApisConfiguration from "layouts/apartment/CloudApisConfiguration";
import CloudApis from "./CloudApis";

const macroDeviceTypes = [
  "device1",
  "device2",
  "device3",
  "device4",
  "device5",
  "device8",
  "device9",
  "device10",
  "device13",
  "device15",
];

function Apartment({
  onnaId,
  sendInitSocket,
  sendEndSocket,
  apartment,
  apartmentDatabase,
  apartmentId,
  individualApartmentId,
  project,
  uid,
  token,
  roomMacros,
  apartmentSchedules,
  actSendExecuteMacroServer,
  macroDevices,
  scheduleDevices,
  database,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openMacro, setOpenMacro] = useState(false);
  const [macrosArray, setMacrosArray] = useState([]);
  const [schedulesArray, setSchedulesArray] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    sendEndSocket();
    if (onnaId !== "virtual")
      sendInitSocket({ onnaId, userId: uid, apartmentId, userName: "cloud", token });
    return () => {
      sendEndSocket();
    };
  }, [onnaId, uid]);

  useEffect(() => {
    const { macros, roomNodeId, bpNodeId } = roomMacros;
    const array = [];
    if (macros) {
      Object.keys(macros).forEach((key) => {
        array.push({
          key,
          name: macros[key]?.name,
          roomNodeId,
          bpNodeId,
        });
      });
    }
    setMacrosArray(array);
  }, [roomMacros]);

  useEffect(() => {
    const array = [];
    Object.keys(apartmentSchedules).forEach((key) => {
      if (apartmentSchedules[key]?.events?.length > 0) array.push(apartmentSchedules[key]);
    });
    setSchedulesArray(array);
  }, [apartmentSchedules]);

  const getTabsInfo = () => [
    { label: t("apartment"), icon: "house" },
    { label: t("schedules"), icon: "access_time" },
    { label: t("moments"), icon: "movieFilter" },
    { label: "Cloud APIs", icon: "cloud" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar
        title={apartmentDatabase.name}
        route={`/projects/${project?.name}/${apartmentDatabase?.name}`}
        firstLink="/projects/"
        secondLink={`/projects/${project?.uid}`}
      />
      <MDBox minHeight={800}>
        <MDAppBar tabsInfo={getTabsInfo()} tabValue={tabValue} setTabValue={setTabValue} />
        <Modal
          open={openSchedule}
          onClose={() => {
            setOpenSchedule(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "80vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <ScheduleEdit
              scheduleData={{}}
              deviceNodeId=""
              devices={scheduleDevices}
              apartmentSchedules={apartmentSchedules}
              setOpen={setOpenSchedule}
            />
          </MDBox>
        </Modal>
        <Modal
          open={openMacro}
          onClose={() => {
            setOpenMacro(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <MDBox
            mt={3}
            sx={12}
            sm={8}
            style={{
              position: "absolute",
              width: "80vw",
              left: "50%",
              transform: "translate(-50%, 0%)",
            }}
          >
            <MacroEdit
              macroData={{ roomNodeId: individualApartmentId }}
              devices={macroDevices}
              setOpen={setOpenMacro}
            />
          </MDBox>
        </Modal>
        {tabValue === 0 && (
          <MDBox>
            <CloudApis projectId={project?.uid} apartmentId={apartmentId} />
            {apartment?.children?.map((room) => (
              <Room room={room} key={room.nodeId} />
            ))}
          </MDBox>
        )}
        {tabValue === 1 && scheduleDevices.length > 0 && (
          <MDBox mb={1}>
            <MDBox mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <MDTypography color="secondary" variant="h6">
                {t("add").toUpperCase()}
              </MDTypography>
              <MDBox ml={2} display="flex">
                <MDIconButton
                  onClick={() => {
                    setOpenSchedule(true);
                  }}
                  color="info"
                  size="small"
                >
                  <Icon>add</Icon>
                </MDIconButton>
              </MDBox>
            </MDBox>
            <Grid container>
              {schedulesArray.map((scheduleData) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  mr={3}
                  key={scheduleData?.deviceNodeId}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Schedule
                    apartmentSchedules={apartmentSchedules}
                    scheduleData={scheduleData}
                    devices={scheduleDevices}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )}
        {tabValue === 2 && macroDevices.length > 0 && (
          <MDBox mb={2}>
            <MDBox mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <MDBox mr={2}>
                <MDIconButton
                  onClick={() => {
                    setOpenMacro(true);
                  }}
                  color="info"
                  size="small"
                >
                  <Icon>add</Icon>
                </MDIconButton>
              </MDBox>
              <MDTypography color="secondary" variant="h6">
                {t("moments").toUpperCase()}
              </MDTypography>
            </MDBox>
            <Grid container>
              {macrosArray.map((macroData) => (
                <Grid item xs={12} md={6} lg={3} mr={3} key={macroData?.key}>
                  <Macro
                    macroData={macroData}
                    actSendExecuteMacroServer={actSendExecuteMacroServer}
                    devices={macroDevices}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )}
        {tabValue === 3 && (
          <CloudApisConfiguration project={project} apartmentId={apartmentId} database={database} />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Apartment.defaultProps = {
  onnaId: "",
  apartmentId: "",
  individualApartmentId: "",
  apartment: {},
  apartmentDatabase: {
    name: "",
  },
  project: {
    name: "",
    projectId: "",
  },
  uid: "",
  token: "",
  roomMacros: {},
  apartmentSchedules: {},
  macroDevices: [],
  scheduleDevices: [],
};

Apartment.propTypes = {
  onnaId: PropTypes.string,
  uid: PropTypes.string,
  token: PropTypes.string,
  apartment: PropTypes.object,
  apartmentDatabase: PropTypes.object,
  apartmentId: PropTypes.string,
  individualApartmentId: PropTypes.string,
  project: PropTypes.object,
  sendInitSocket: PropTypes.func.isRequired,
  sendEndSocket: PropTypes.func.isRequired,
  roomMacros: PropTypes.object,
  apartmentSchedules: PropTypes.object,
  macroDevices: PropTypes.array,
  scheduleDevices: PropTypes.array,
  actSendExecuteMacroServer: PropTypes.func.isRequired,
  database: PropTypes.object.isRequired,
};

const mapStateToProps = (state, { onnaId, projectId, apartmentId }) => {
  const {
    projectsReducer,
    apartmentsReducer,
    configurationReducer,
    onnaDevicesReducer,
    mainUserReducer,
    macroReducer,
    scheduleReducer,
  } = state;
  let apartment = {};
  let apartmentDatabase = {};

  const ids = apartmentId.split("@");
  let individualApartmentId = "";

  if (ids.length === 2) {
    const [newOnnaId, newApartmentId] = ids;
    if (onnaId === newOnnaId) {
      individualApartmentId = newApartmentId;
    }
  }

  const { macros } = macroReducer;
  const { schedules } = scheduleReducer;

  let roomMacros = {};
  let apartmentSchedules = {};

  const macroDevices = [];
  const scheduleDevices = [];

  if (Object.hasOwnProperty.call(apartmentsReducer, apartmentId)) {
    apartmentDatabase = apartmentsReducer[apartmentId];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const foundApartment of configurationReducer) {
    const { nodeId, children: rooms } = foundApartment;
    if (nodeId === individualApartmentId) {
      apartment = foundApartment;
      if (rooms) {
        // eslint-disable-next-line no-restricted-syntax
        for (const foundRoom of rooms) {
          const { children, name } = foundRoom;
          if (children) {
            children.forEach((device) => {
              if (device.macro && macroDeviceTypes.indexOf(device.type) !== -1) {
                macroDevices.push({ ...device, roomName: name });
              }
              if (device.schedule && macroDeviceTypes.indexOf(device.type) !== -1) {
                scheduleDevices.push({ ...device, roomName: name });
              }
            });
          }
        }
      }
    }
  }

  if (typeof macros === "object" && macros !== null) {
    if (Object.hasOwnProperty.call(macros, individualApartmentId)) {
      if (Object.hasOwnProperty.call(macros[individualApartmentId], individualApartmentId)) {
        roomMacros = macros[individualApartmentId][individualApartmentId];
      }
    }
  }

  if (typeof schedules === "object" && schedules !== null) {
    if (Object.hasOwnProperty.call(schedules, individualApartmentId)) {
      apartmentSchedules = schedules[individualApartmentId];
    }
  }

  const { uid, token } = mainUserReducer;

  return {
    apartment,
    apartmentDatabase,
    individualApartmentId,
    onnaDevice: onnaDevicesReducer[onnaId],
    onnaId,
    project: projectsReducer && projectsReducer[projectId],
    uid,
    token,
    roomMacros,
    macroDevices,
    apartmentSchedules,
    scheduleDevices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendInitSocket: (data) => {
    dispatch(initSocket(data));
  },
  sendEndSocket: () => {
    dispatch(endSocket());
  },
  actSendExecuteMacroServer: (data) => {
    dispatch(sendExecuteMacroServer(data));
  },
});

const ProjectWithConnect = connect(mapStateToProps, mapDispatchToProps)(Apartment);

function ProjectWrapper(props) {
  const { onnaId, projectId, apartmentId } = useParams();
  return (
    <ProjectWithConnect
      onnaId={onnaId}
      projectId={projectId}
      apartmentId={apartmentId}
      {...props}
    />
  );
}

export default ProjectWrapper;
