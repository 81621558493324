/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useState } from "react";

// @mui material components
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Room from "layouts/apartment/Room";
// import Macro from "layouts/apartment/Devices/MacroClient";
// import Schedule from "layouts/apartment/Devices/Schedule";
// import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import ScheduleEdit from "layouts/apartment/Devices/schedule/ScheduleEdit";
import MacroEdit from "layouts/apartment/Devices/macro/MacroEditClient";
// import MDIconButton from "components/MDIconButton";
import { initClient } from "store/actions/mainUserActions";
import { sendExecuteMacroServer } from "store/actions/macroActions";
import MDAppBar from "components/MDAppBar";
import {
  accessControlGetLockDetailClient,
  accessControlLockUnlockClient,
} from "store/actions/accessControlActions";
import CloudApis from "./CloudApis";

const macroDeviceTypes = [
  "device1",
  "device2",
  "device3",
  "device4",
  "device5",
  "device8",
  "device9",
  "device10",
  "device13",
  "device15",
];

function Apartment({
  apartment,
  apartmentId,
  projectId,
  roomMacros,
  apartmentSchedules,
  // actSendExecuteMacroServer,
  macroDevices,
  scheduleDevices,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openMacro, setOpenMacro] = useState(false);
  // const [macrosArray, setMacrosArray] = useState([]);
  // const [schedulesArray, setSchedulesArray] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const { macros, roomNodeId, bpNodeId } = roomMacros;
    const array = [];
    if (macros) {
      Object.keys(macros).forEach((key) => {
        const { name, system } = macros[key];
        if (!system) {
          array.push({
            key,
            name,
            roomNodeId,
            bpNodeId,
          });
        }
      });
    }
    // setMacrosArray(array);
  }, [roomMacros]);

  useEffect(() => {
    const array = [];
    Object.keys(apartmentSchedules).forEach((key) => {
      if (apartmentSchedules[key]?.events?.length > 0) array.push(apartmentSchedules[key]);
    });
    // setSchedulesArray(array);
  }, [apartmentSchedules]);

  const getTabsInfo = () => [
    { label: t("apartment"), icon: "house" },
    // { label: t("schedules"), icon: "access_time" },
    // { label: t("moments"), icon: "movieFilter" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar isClient />
      <MDAppBar tabsInfo={getTabsInfo()} tabValue={tabValue} setTabValue={setTabValue} />
      <Modal
        open={openSchedule}
        onClose={() => {
          setOpenSchedule(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "80vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <ScheduleEdit
            scheduleData={{}}
            deviceNodeId=""
            devices={scheduleDevices}
            apartmentSchedules={apartmentSchedules}
            setOpen={setOpenSchedule}
          />
        </MDBox>
      </Modal>
      <Modal
        open={openMacro}
        onClose={() => {
          setOpenMacro(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        <MDBox
          mt={3}
          sx={12}
          sm={8}
          style={{
            position: "absolute",
            width: "80vw",
            left: "50%",
            transform: "translate(-50%, 0%)",
          }}
        >
          <MacroEdit
            macroData={{ roomNodeId: apartmentId }}
            devices={macroDevices}
            setOpen={setOpenMacro}
          />
        </MDBox>
      </Modal>
      <MDBox minHeight={800}>
        {tabValue === 0 && (
          <MDBox>
            <CloudApis projectId={projectId} apartmentId={apartmentId} client />
            {apartment?.children?.map((room) => {
              const { protectPage } = room;
              if (!protectPage) {
                return <Room room={room} key={room.nodeId} />;
              }
              return <div />;
            })}
          </MDBox>
        )}
        {/* {tabValue === 1 && scheduleDevices.length > 0 && (
          <MDBox mb={1}>
            <MDBox mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <MDTypography color="secondary" variant="h6">
                {t("add").toUpperCase()}
              </MDTypography>
              <MDBox ml={2} display="flex">
                <MDIconButton
                  onClick={() => {
                    setOpenSchedule(true);
                  }}
                  color="info"
                  size="small"
                >
                  <Icon>add</Icon>
                </MDIconButton>
              </MDBox>
            </MDBox>
            <Grid container>
              {schedulesArray.map((scheduleData) => (
                <Grid item xs={12} md={6} lg={3} mr={3} key={scheduleData?.deviceNodeId}>
                  <Schedule
                    apartmentSchedules={apartmentSchedules}
                    scheduleData={scheduleData}
                    devices={scheduleDevices}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )}
        {tabValue === 2 && macroDevices.length > 0 && (
          <MDBox mb={2}>
            <MDBox mb={2} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <MDBox mr={2}>
                <MDIconButton
                  onClick={() => {
                    setOpenMacro(true);
                  }}
                  color="info"
                  size="small"
                >
                  <Icon>add</Icon>
                </MDIconButton>
              </MDBox>
              <MDTypography color="secondary" variant="h6">
                {t("moments").toUpperCase()}
              </MDTypography>
            </MDBox>
            <Grid container>
              {macrosArray.map((macroData) => (
                <Grid item xs={12} md={6} lg={3} mr={3} key={macroData?.key}>
                  <Macro
                    macroData={macroData}
                    actSendExecuteMacroServer={actSendExecuteMacroServer}
                    devices={macroDevices}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        )} */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Apartment.defaultProps = {
  apartmentId: "",
  projectId: "",
  apartment: {
    name: "",
  },
  roomMacros: {},
  apartmentSchedules: {},
  macroDevices: [],
  scheduleDevices: [],
};

Apartment.propTypes = {
  apartment: PropTypes.object,
  apartmentId: PropTypes.string,
  projectId: PropTypes.string,
  roomMacros: PropTypes.object,
  apartmentSchedules: PropTypes.object,
  macroDevices: PropTypes.array,
  scheduleDevices: PropTypes.array,
  // actSendExecuteMacroServer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { configurationReducer, mainUserReducer, macroReducer, scheduleReducer } = state;
  const { uid, email, apartmentId, projectId } = mainUserReducer;

  let individualApartmentId = apartmentId;
  if (apartmentId) {
    if (apartmentId.indexOf("@") !== -1) {
      [, individualApartmentId] = apartmentId.split("@");
    }
  }

  let apartment = {};
  const { macros } = macroReducer;
  const { schedules } = scheduleReducer;

  let roomMacros = {};

  const macroDevices = [];
  const scheduleDevices = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const foundApartment of configurationReducer) {
    const { nodeId, children: rooms } = foundApartment;
    if (nodeId === individualApartmentId) {
      apartment = foundApartment;
      if (rooms) {
        // eslint-disable-next-line no-restricted-syntax
        for (const foundRoom of rooms) {
          const { children, name, protectPage } = foundRoom;
          if (children && !protectPage) {
            children.forEach((device) => {
              if (device.macroClient && macroDeviceTypes.indexOf(device.type) !== -1) {
                macroDevices.push({ ...device, roomName: name });
              }
              if (device.scheduleClient && macroDeviceTypes.indexOf(device.type) !== -1) {
                scheduleDevices.push({ ...device, roomName: name });
              }
            });
          }
        }
      }
    }
  }

  if (typeof macros === "object" && macros !== null) {
    if (Object.hasOwnProperty.call(macros, individualApartmentId)) {
      roomMacros = macros[individualApartmentId];
    }
  }

  return {
    apartment,
    apartmentId: individualApartmentId,
    clientData: { uid, email },
    roomMacros,
    macroDevices,
    apartmentSchedules: schedules,
    scheduleDevices,
    projectId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendInitClient: (clientData) => {
    dispatch(initClient(clientData));
  },
  actSendExecuteMacroServer: (data) => {
    dispatch(sendExecuteMacroServer(data));
  },
  actAccessControlGetLockDetail: (data) => {
    dispatch(accessControlGetLockDetailClient(data));
  },
  actAccessControlLockUnlock: (data) => {
    dispatch(accessControlLockUnlockClient(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
